import React from "react";
import './HomeLayout.css';

const HomeLayout = ({children}) => {
  return (
    <div className="home-container">
      <div className="home-content">
        <h1>Welcome</h1>
        <p>Please continue by logging in.</p>
        <div>
          {children}
        </div>
      </div>
    </div>
  );
}

export default HomeLayout;
