// src/components/IntroSection.js
import React from 'react';
import './InteriorSection.css';

const InteriorSection = () => {

  return (
    <section className="interior-section py-4 text-center">
      <div className="row">
        <div className="col-12 mx-auto">
          <h1 className="intro-title">
            아이닷 컨셉 디자인
          </h1>
          <p className="intro-description">
            원하는 컨셉을 바로 생성해주는 AI 어시스턴트
          </p>
        </div>
      </div>
    </section>
  );
};

export default InteriorSection;
