// src/components/PromptInputSection.js
import React, { useState } from 'react';
import './PromptInputSection.css';
import AlertMessage from './atoms/AlertMessage';
import CustomDropdown from './atoms/CustomDropdown';

const PromptInputSection = ({ onGenerate, selectedSize, setSelectedSize, promptText, setPromptText, textareaRef }) => {
  const [showAlert, setShowAlert] = useState(false);

  const handleSizeChange = (e) => {
    setSelectedSize(e.target.value);
  };

  const sizeOptions = [
    { value: "16:9", label: "16:9" },
    { value: "4:3", label: "4:3" },
    { value: "1:1", label: "1:1" },
  ];

  const adjustTextareaHeight = (element) => {
    if (element) {
      element.style.height = "auto";
      element.style.height = `${element.scrollHeight}px`;
    }
  };

  const handlePromptTextChange = (e) => {
    setPromptText(e.target.value);
    adjustTextareaHeight(e.target);
  };
  
  const handleGenerate = () => {
    if (!promptText) {
      setShowAlert(true);
      return;
    }

    onGenerate(promptText);
  };

  return (
    <div className="prompt-input-section">
      {showAlert && (
        <AlertMessage 
          message="키워드를 입력해주세요!" 
          onClose={() => setShowAlert(false)} 
        />
      )}
      {/* <div className="input-container">
        <label htmlFor="size-input" className="prompt-label">Size</label>
        <input
          id="size-input"
          className="size-input"
          value="16:9"
          disabled
        />
      </div> */}
      <div className="input-container">
        <CustomDropdown
          id="size-dropdown"
          label="Size"
          options={sizeOptions}
          value={selectedSize}
          onChange={(value) => setSelectedSize(value)}
        />
      </div>

      <div className="input-container">
        <label htmlFor="prompt-input" className="prompt-label">Keyword</label>
        <textarea
          id="prompt-input"
          className="prompt-input"
          ref={textareaRef}
          value={promptText}
          onChange={handlePromptTextChange}
          placeholder="생성할 이미지의 설명을 입력하세요..."
          rows="1"
        />
      </div>
      <button className="generate-button" onClick={handleGenerate}>
        만들기
      </button>
    </div>
  );
};

export default PromptInputSection;
