// src/components/AlertMessage.js
import React from 'react';
import './AlertMessage.css';

const AlertMessage = ({ message, onClose }) => {
  return (
    <div className="alert-overlay" onClick={onClose}>
      <div className="alert-box" onClick={(e) => e.stopPropagation()}>
        <p className="alert-message">{message}</p>
        <button className="alert-close-button" onClick={onClose}>
          닫기
        </button>
      </div>
    </div>
  );
};

export default AlertMessage;
