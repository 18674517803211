import React, { useEffect } from 'react';
import HomeLayout from '../layout/HomeLayout';
import { useAuth } from "../login/AuthProvider";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { $post } from "../utils/common";
import Swal from "sweetalert2";

const Home = () => {
  const userLocalLang = navigator.language || navigator.userLanguage;
  const appUrl = process.env.REACT_APP_URL;
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  const { isLoggedIn, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/interior");
    }
  }, [isLoggedIn, navigate]);

  const onGoogleSuccess = async (response) => {
    const resData = jwtDecode(response.credential);

    const res = await $post("/api/google-login", {
      email: resData.email,
      nickname: resData.name,
      profile_image_url: resData.picture,
      access_token_expires_in: resData.exp,
      lang: userLocalLang === "ko-KR" ? "KO" : "EN",
    });

    const userInfo = res.data[0];
    if (res.status === 200) {
      login({
        ...userInfo,
      });
      if(resData.active_yn !== "Y"){
        Swal.fire({
          iconHtml: `<img src="${appUrl}/assets/images/idot-logo.png" class="swal-custom-icon" alt="logo" />`,
          title: "구독이 필요한 서비스입니다.",
          showCancelButton: false,
          confirmButtonText: "구독하러 가기",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload(); 
            // window.open(
            //   "https://www.wonderslab.ai/pricing",
            //   "_blank"
            // );
          }
        });
      }else{
        navigate("/interior");
      }
    }
  };

  const onGoogleFailure = (response) => {
    console.log("Login Failed:", response);
  };

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <HomeLayout>
      {/* {appUrl.includes("localhost") && (
          <div className="google-login-button">
            <GoogleLogin
              onSuccess={onGoogleSuccess}
              onError={onGoogleFailure}
            />
          </div>
        )} */}
          <GoogleLogin
            // onSuccess={(response) => console.log("Login Success:", response)}
            onSuccess={onGoogleSuccess}
            onError={() => console.log("Login Failed")}
            render={({ onClick }) => (
              <button className="google-login-button" onClick={onClick}>
                <img src="./assets/icons/google-icon.svg" alt="Google icon" className="google-icon" />
                <span>Continue with Google</span>
              </button>
            )}
          />
      </HomeLayout>
    </GoogleOAuthProvider>
  )
};

export default Home;
