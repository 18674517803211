import "./App.css";

import React from "react";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import Interior from "./pages/Interior";
import UserDetail from "./pages/UserDetail";
import PageNotFound from "./pages/404";
import NotAuthorized from "./pages/401";
import InternalServerError from "./pages/500";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthProvider from "./login/AuthProvider";

import "./locales/i18n";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <div className="App">
          {/* <Header /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/interior" element={<Interior />} />
            <Route path="/userdetail" element={<UserDetail />} />
            <Route path="/404" element={<PageNotFound />} />
            <Route path="/401" element={<NotAuthorized />} />
            <Route path="/500" element={<InternalServerError />} />
          </Routes>
          <Footer />
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
