import React from "react";
import RiseLoader from "react-spinners/RiseLoader";
import './Loading.css';

function Loading(props) {
  return (
    <div className="modal-loading">
      <div className="modal-loading-animation">
        {/* <HashLoader color="#f2295b" /> */}
        <RiseLoader color="#f2295b" />
        {props.message && (
          <div className="loading-message">{props.message}</div>
        )}
      </div>
    </div>
  );
}

export default Loading;
