import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import InteriorSection from '../component/InteriorSection';
import PromptInputSection from '../component/PromptInputSection';
import ImageList from '../component/ImageList';
import ImageResult from '../component/ImageResult';

const imageTypes = [
  {
    href: "/interior/1.jpg",
    keyword: "165㎡  타운하우스 거실, 화이트 벽에, 그레이 포세린 타일 바닥, 검은색 가죽 쇼파, 모던한 디자인의 투명한 아크릴 테이블, 검은색 러그,  화이트 커튼, 큰 창문으로 들어오는 아침햇살, 창문 밖으로 타운하우스 마당의 풍경",
  },
  {
    href: "/interior/2.jpg",
    keyword: "165㎡ 로맨틱 빈티지한 타운하우스 거실, 화이트 프래임에 빈티지한 아트원, 화이트 우드 바닥, 빈티지한 레드 브라운 쇼파,  화이트 거실 탁자, 베이지색 러그,  레드 브라운 암막 커튼, 큰 창문으로 들어오는 오후의 햇살, 창문 밖으로 데크가 깔린 테라스 풍경",
  },
  {
    href: "/interior/3.jpg",
    keyword: "지중해 리조트 스타일 거실 인테리어, 화이트 벽, 블루 포인트 소파, 타원형 창문,  넓은 화이트 거실 탁자, 그리스 스타일 러그 ,  창문 밖으로 데크가 깔린 테라스 풍경",
  },
  {
    href: "/interior/4.jpg",
    keyword: "지중해 리조트 스타일 거실 인테리어, 화이트 벽, 블루 포인트 소파, 타원형 창문,  넓은 화이트 거실 탁자, 그리스 스타일 러그 ,  창문 밖으로 데크가 깔린 테라스 풍경",
  },
  {
    href: "/interior/5.jpg",
    keyword: "따뜻하고 아늑한 보헤미안 스타일 거실 디자인. 다양한 색상이 조화롭게 어우러진 공간으로, 테라코타, 머스터드, 와인색 등 따뜻한 색조를 바탕으로 기하학적 패턴과 에스닉 디자인의 러그와 쿠션을 레이어링하여 생동감을 더합니다. 거친 질감의 라탄과 우드 소재 가구를 중심으로, 벽면에는 수공예품이나 태피스트리로 장식하여 개성을 강조합니다. 크고 작은 실내 식물을 곳곳에 배치하고, 마크라메 화분 걸이를 사용해 자연 친화적인 느낌을 연출합니다. 빈티지 소파와 편안한 좌식 공간, 담요와 쿠션을 겹쳐 따뜻한 분위기를 조성합니다.",
  },
  {
    href: "/interior/6.jpg",
    keyword: "30평 아파트 주방, 싱크대 상부장은 화이트. 하부장은 다크우드, 가스렌지 후드 화이트, 4인용 다크우드 식탁, 하얀색 모던한 식탁의자, 모던하고 투명한 긴 식탁등 화이트 벽지, 포인트 다크우드 패널, 다크우드 마루바닥",
  },
  {
    href: "/interior/7.jpg",
    keyword: "기하학적 패턴이 매력적인 소형 아파트 주방, 싱크대 상부장은 무광 블랙. 하부장은 화이트, 화이트 상판, 화이트 긴 사각타일,  실버 빌트인 냉장고 , 실버 가스렌지 후드,아르데코 스타일 2인용 검은색 원형테이블,투명한 식탁의자, 아르데코 스타일 식탁등, 화이트 벽지, 화이트 포세린 타일 바닥",
  },
  {
    href: "/interior/8.jpg",
    keyword: "바다의 감성을 담은 주방인테리어 컨셉 디자인, 상부장 없는 주방,  화이트 그릇 벽걸이 선반, 하부장은 자연스러운 질감의 라이트 우드, 시원한 색감의 타일, 코스탈 감성의 6인식탁, 우드 의자, 조명",
  },
  {
    href: "/interior/9.jpg",
    keyword: "타운하우스 빈티지 스타일 주방 디자인. 내추럴 우드와 화이트 컬러를 기반으로 한 클래식한 분위기, 곳곳에 빈티지  소품이 조화롭게 배치된 공간. 화이트 타일과 브라운 우드 조합의 벽면,  오픈 선반에는 빈티지 머그컵 등을 진열, 라운드 형태의 식탁과 클래식 의자, 수공예 스타일의 식탁보, 자연광이 잘 드는 창문에는 레이스 커튼",
  },
  {
    href: "/interior/10.jpg",
    keyword: "안방인테리어, 하얀색 침대, 침대 맞은편에 하얀색 슬라이딩 불박이장, 그레이컬러 방문, 연한 그레이 우드 바닥, 벽에 문이 없는 아치형 도어공간 뒤로 드레스룸과 화장실로 연결되는 구조, 천장에 빌트인 에어컨",
  },
  {
    href: "/interior/11.jpg",
    keyword: "165㎡ 사이즈 아파트 안방인테리어,침대가 정면으로 보이는 뷰, 화이트 패널 침대, 연한 노란색 침구, , 화이트 방문, 연한 우드 바닥, 벽에 다른 곳으로 연결되는 공간 드레스룸과 화장실로 연결되는 구조, 노란색 쉬폰 커튼",
  },
  {
    href: "/interior/12.jpg",
    keyword: "코지하고 미니멀한 침실 인테리어 컨셉, 천장까지 연결된 템바보드 침대 헤드, 베이지색 침구 옆으로 심플한 베이지색 붙장이장,",
  },
  {
    href: "/interior/13.jpg",
    keyword: "뉴트럴 톤과 우드 바닥으로 차분한 분위기. 부드러운 침구와 엔틱한 헤드보드,  협탁과 따뜻한 조명 스탠드 배치. 얇은 커튼으로 자연광 조절, 벽에는 아트워크나 사진으로 포인트. 작은 화분이나 드라이플라워로 생기를 더한 아늑한 안방 공간",
  },
  {
    href: "/interior/14.jpg",
    keyword: "서재 인테리어, 화이트 컬러의 서재 테이블, 책상위에 맥북, 아르때미떼 스타일 스탠드 조명, 서재용 베이지색 컴퓨터 의자, 책상 오른쪽 옆으로 창문에 하얀색 블라인드, 책상 왼쪽 옆으로 밝은 우드 책꽂이, 밝은 우드 바닥",
  },
  {
    href: "/interior/15.jpg",
    keyword: "서재 인테리어 컨셉 인테리어,책상 앞면, 빨간색 유명 디자인 가구 레트로 의자, 모던한 블랙상판에 스테인리스 소재의 책상다리, 책상위에 맥북, 레트로 스타일 빨간색 조명, 화이트 책꽂이, 화이트 도어, 책상 오른쪽 옆으로 창문에 검은색 블라인드, 밝은 우드 바닥",
  },
  {
    href: "/interior/16.jpg",
    keyword: "모던한 서재 인테리어 컨셉 인테리어,고급스럽고 큰 각진 테이블, 검은색 서재 용 의자, 한쪽 벽면으로 검은색 붙박이 책장이 있고, 책상 위 긴 다운 라이트가 있다",
  },
  {
    href: "/interior/17.jpg",
    keyword: "아이방 인테리어 컨셉 인테리어,  벙커 스타일 어린이 침대, 놀이용 작은 책상, 어린이 의자, 파스텔 톤 침구,가구, 커튼, 화이트 도어, 동그란 창문",
  },
  {
    href: "/interior/18.jpg",
    keyword: "아이방 인테리어 컨셉 인테리어, 하얀색 어린이 침대, 핑크와 블루 조합의 침구, 별모양 어린이 쿠션, 파스텔 톤 장난감 정리함, 하트모양 러그, 별 모양이 있는 커튼, 화이트 도어, ",
  },
  {
    href: "/interior/19.jpg",
    keyword: "한국식 화장실 인테리어 컨셉, 하얀색 벽 타일,  회색 바닥 타일, 하얀색 변기, 변기 옆으로 세면대, 세면대 옆에 ,샤워부스, 샤워부스와 세면대 공간 사이 유리 벽으로 구분, 샤워기 옆 벽으로 검은색 조적식 선반, 샤워기 스틸 수전, ",
  },
  {
    href: "/interior/20.jpg",
    keyword: "모던 컨트리한 분위기의 아파트 화장실 인테리어 컨셉, 화이트 육각 타일 , 컨트리한 분위기의 하얀색 변기, 변기 옆으로 컨트리한 느낌의 세면대, 컨트리한 골드 수전, 세면대 옆에  욕조, 샤워기",
  },
  {
    href: "/interior/21.jpg",
    keyword: "화장실 인테리어, 대리석 모양 타일이 벽면과 바닥으로 이어지며 통일감을 준다, 세면대는 대리석 위에 탑볼형식, 네모난 거울, 조적식 가벽으로 분리된 변기공간",
  },
  {
    href: "/interior/22.jpg",
    keyword: "아라비카 컨셉 분위기의 카페 파사드 디자인, 외벽에 정교한 아라비안 문양이 새겨진  모자이크 타일,외벽 옆으로 통창, 통창 옆으로 아라비안 문양이 새겨진 나무 출입문, 통창 안으로 보이는 카페 인테리어 공간, 통창 유리위로 나무 간판 \"idot\"",
  },
  {
    href: "/interior/23.jpg",
    keyword: "아라비안 컨셉의 카페를 위해 계산대는 정교한 아라비안 문양이 새겨진 나무 패널이나 모자이크 타일로 전면을 장식하고, 상판은 화이트 대리석이나 짙은 나무로 고급스럽게 마감하며, 금속 펜던트 조명을 설치해 따뜻한 분위기를 연출합니다. 손님용 테이블은 2~4인용 둥근 테이블과 긴 직사각형 그룹 테이블로 구성하되, 나무나 대리석 소재를 사용하고, 중앙에는 아라비안 랜턴이나 미니 화분으로 장식해 감각적인 디테일을 더합니다. 의자는 나무 또는 금속 프레임에 벨벳이나 인조 가죽 쿠션을 사용하고, 소파 좌석은 풍성한 쿠션과 짙은 색감의 전통 문양 천으로 꾸며 아늑함을 강조하며, 계산대는 입구 근처에 배치하고 테이블은 간격을 넉넉히 두어 편안한 동선을 유지합니다.",
  },
  {
    href: "/interior/24.jpg",
    keyword: "미니멀한 카페 파사드 인테리어, 화이트 외관에 , 통 유리창으로 카페 내부 인테리어가 보인다, 화이트 라인의 유리 도어, 하얀벽 위에 작은 정사각형 우드 간판에 \"idot\", 우드 패널",
  },
  {
    href: "/interior/25.jpg",
    keyword: "미니멀하고 코지한 카페 인테리어, 화이트 베이지컬러, 카운터는 우드 패널에 스테인리스 스틸 상판으로 심플하게 디자인, 간접 조명, 우드 소재의 미니멀한 디자인의 테이블과 의자, 라인 드로잉 액자 포인트",
  },
  {
    href: "/interior/26.jpg",
    keyword: "미니멀한 인테리어의 카페, 직선적이고 간결한 라이트 오크와 스테인리스 스틸 소재의 카운터, 직선적인 화이트 컬러의 테이블과 의자,",
  },
  {
    href: "/interior/27.jpg",
    keyword: "카운터와 테이블이 한눈에 보이는 카페인테리어 디자인, 간결한 화이트 우드 컨셉,  카페 테이블과 의자가 매장을 채우고 있다, 노출 천장",
  },
  {
    href: "/interior/28.jpg",
    keyword: "카페 인테리어 파사드 디자인, 외벽 아래 부분은 네추럴한 우드 판넬, 위 부분은 창문, 우드 도어, 작은 입간판에 \"cafe\"",
  },
  {
    href: "/interior/29.jpg",
    keyword: "카페 인테리어 파사드 디자인, 화이트 외벽 아래 부분은 네추럴한 우드 판넬,  넒은 통 창문, 우드 도어, 작은 스탠드형 간판에 \"cafe\"",
  },
  {
    href: "/interior/30.jpg",
    keyword: "카운터와 테이블이 한눈에 보이는 카페인테리어 디자인, 간결한 화이트 우드 컨셉,  카페 테이블과 의자가 매장을 채우고 있다, 노출 천장",
  },
  {
    href: "/interior/31.jpg",
    keyword: "카운터와 테이블이 한눈에 보이는 카페인테리어 디자인, 간결한 화이트 우드 컨셉,  카페 테이블과 의자가 매장을 채우고 있다, 노출 천장",
  },
  {
    href: "/interior/33.jpg",
    keyword: "트로피컬 분위기 카페 인테리어, 노란색, 하늘색, 빨간색  테이블과 의자, 카운터, 트로피컬 분위기와 어울리는  다운라이팅",
  },
  {
    href: "/interior/34.jpg",
    keyword: "바우하우스 스타일 카페 파사드 익스테리어, 외벽은 노출 콘트리트, 통창문, 통 창문안으로 보이는 카페 인테리어,  스테인리스 스틸 소재의 문, 조그만 파란색 정사각형 간판",
  },
  {
    href: "/interior/35.jpg",
    keyword: "바우하우스 스타일 카페 인테리어,  노출 콘트리트벽에 , 스테인리스 소재와 블랙 블루 컨셉의 카운터 그리고 카페 테이블, 의자",
  },
  {
    href: "/interior/36.jpg",
    keyword: "바우하우스 스타일 카페 인테리어,  노출 콘트리트벽에 , 스테인리스 소재와 카페 테이블, 의자, 블랙 블루 컨셉의 카운터에 스테인리스 상판",
  },
  {
    href: "/interior/37.jpg",
    keyword: "펍 파사드 익스테리어, 작고 얇은 네온사인 간판\"pub\" 통창, 야외 테라스, 테이블과 의자, 네온사인이 걸려있는 투명한 문",
  },
  {
    href: "/interior/38.jpg",
    keyword: "미래지향적인 디자인의 펍 공간. 메탈릭 실버와 블랙을 주조색으로 사용하며, 네온 블루와 퍼플 조명을 포인트로 활용해 사이버펑크 감성을 강조. 벽면은 매끈한 메탈 패널 또는 유광 타일로 마감하고, 바 테이블과 의자는 금속과 유리 소재로 제작된 모던한 디자인. 천장에는 LED 스트립 조명을 배치해 공간에 입체감을 더하고, 벽면 일부에 디지털 아트나 홀로그램 디스플레이를 설치. 바닥은 다크 톤으로 통일하고, 곳곳에 독특한 곡선형 구조물이나 레이저 조명으로 미래적인 분위기를 완성.",
  },
  {
    href: "/interior/39.jpg",
    keyword: "멕시칸 아시엔다 스타일 카페 파사드 디자인,테라코타 톤 스터코 외벽과 아치형 창문으로 전통적인 멕시칸 감성을 표현. 다크 우드 창틀과 화려한 타일 장식을 더하고, 선인장과 열대 식물로 활기를 연출. 빈티지 스타일의 손글씨 간판과 아이언 랜턴",
  },
  {
    href: "/interior/40.jpg",
    keyword: "테라코타 타일 바닥, 흙빛 스터코 벽, 노출 목재 보 천장. 멕시칸 패턴 타일로 장식된 우드 계산대. 어두운 우드 테이블과 의자 배치, 선인장과 알로에 장식. 다양한 크기의 테이블로 실용적 구성, 아이언 샹들리에와 벽 조명 추가",
  },
  {
    href: "/interior/41.jpg",
    keyword: " 팝업스토어 익스테리어, 외벽은 메탈릭 실버와 크롬, 일부 반투명 유리를 통해 내부가 보이도록 설계. 입구 상단 악세사리 조형물, 바닥은 글로시 타일로 처리하고, 입구 양옆에는 투명 디스플레이 박스에 악세사리를 공중에 떠 있는 듯한 방식으로 배치. 간판은 네온 라이트",
  },
  {
    href: "/interior/42.jpg",
    keyword: "카페 외관 디자인, 화이트 톤 마감과 필로티 구조의 직선적이고 기하학적인 외관. 1층은 기둥으로 오픈된 공간을 만들고, 기능적인 우드 벤치와 식물 장식을 배치. 2층은 대형 통창 유리와 블랙 메탈 프레임으로 구성, 대칭적이고 균형 잡힌 형태를 강조. 건물 전체는 형태와 기능의 조화를 중심으로 설계되며, 입구는 간결한 디자인의 문과 작은 직사각형 간판",
  },
  {
    href: "/interior/43.jpg",
    keyword: "세련된 디자인의 건축물, 현대적 기하학적 라인, 초대형 유리 패널로 개방감 강조. 콘크리트와 나무 조화로 따뜻하고 도시적 분위기 연출. 외부 좌석과 녹지 통합, 독특한 캐노피와 은은한 조명으로 매력적인 입구 완성. 아방가르드 스타일로 독창적이고 조화로운 외관 구현",
  },
]
function Interior() {
  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const appUrl = process.env.REACT_APP_URL;

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [selectedSize, setSelectedSize] = useState("16:9");
  const [promptText, setPromptText] = useState("");  
  const textareaRef = useRef(null);

  const handleSelectSize = (size) => {
    setSelectedSize(size);
  };

  const handleSetPromptText = (text) => {
    setPromptText(text);
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleGenerate = async (keyword) => {
    let prompt = "";

    setModalData({ prompt, url: null });
    setLoading(true);
    sessionStorage.setItem("stopAssistant", false);

    try {
      const promptRes = await fetch(`${serverUrl}/api/prompt/prompt`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          requestText: keyword,
        }),
      });

      const reader = promptRes.body.pipeThrough(new TextDecoderStream()).getReader();
      const tempAssistantMessage = [];
      const finishRegexp = /(\[\[.*?\]\])/;
      let finishReason = "";

      while (true) {
        if (sessionStorage.getItem("stopAssistant") === "true") {
          reader.cancel();
          reader.releaseLock();
          break;
        }

        let { value, done } = await reader.read();
        if (done) break;
        setLoading(false);

        if (finishRegexp.test(value)) {
          finishReason = value.match(finishRegexp)[0];
          value = "";
        }

        tempAssistantMessage.push(value);

        value = value.replace(/(?:\r\n|\r|\n)/g, "<br />");
        setModalData((prev) => ({
          prompt: prev.prompt + value,
          url: null
        }));
      }

      sessionStorage.setItem("stopAssistant", true);

      prompt = tempAssistantMessage.join("");

      setModalData({
        prompt,
        url: null,
      });

      const response = await fetch(`${serverUrl}/api/prompt/flux`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          aspectRatio: selectedSize,
          prompt,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setModalData({
        prompt,
        url: data.url,
      });
    } catch (error) {
      console.error("Error during API request:", error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalData(null);
  };

  return (
    <main>
      <InteriorSection />
      <PromptInputSection
        onGenerate={handleGenerate}
        selectedSize={selectedSize}
        setSelectedSize={handleSelectSize}
        promptText={promptText}
        setPromptText={handleSetPromptText}
        textareaRef={textareaRef} 
      />
      {modalData && (
        <ImageResult 
          prompt={modalData.prompt} 
          url={modalData.url} 
          loading={loading}
          onClose={closeModal} 
        />
      )}
      <ImageList 
        images={imageTypes} 
        setPromptText={handleSetPromptText}
        textareaRef={textareaRef}
      />
    </main>
  );
}

export default Interior;
