// ImageResult.js
import React from "react";
import "./ImageResult.css";
import Loading from "./atoms/Loading";

const ImageResult = ({ prompt, url, loading, onClose }) => {
  // const handleSave = async () => {
  //   try {
  //     const response = await fetch(url, { mode: "cors" });
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     const blob = await response.blob();
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.setAttribute("download", "generated_image.png"); 
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //     URL.revokeObjectURL(link.href); 
  //   } catch (error) {
  //     console.error("Error downloading the image:", error);
  //   }
  // };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-icon" onClick={onClose}>
          <img src="./assets/icons/ico-close.svg" alt="Close" />
        </button>

        {loading ? (
          <Loading /> 
        ) : (
          <>
            {/* <div className="label-prompt">prompt</div>
            {prompt ? (
              <div className="modal-prompt">
                {prompt}
              </div>
            ) : (
              <div className="modal-prompt">---</div>
            )} */}
            {url ? (
              <img src={url} alt="Generated Result" className="modal-image" />
            ) : (
              <Loading /> 
            )}
          </>
        )}
        {/* <div className="modal-buttons">
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ImageResult;
