// src/components/ImageList.js
import React from "react";
import './ImageList.css';

const ImageList = ({ images, setPromptText, textareaRef }) => {
  const backToTop = () => {
    const position =
      document.documentElement.scrollTop || document.body.scrollTop;

    if (position) {
      window.requestAnimationFrame(() => {
        window.scrollTo(0, 0);
      });
    }
  }
  const handleSelect = (keyword) => {
    backToTop();
    setPromptText(keyword);

    if (textareaRef.current) {
      textareaRef.current.value = keyword; 
      textareaRef.current.style.height = "auto"; 
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; 
    }
  };

  return (
    <div className="image-list row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 g-4">
      {images.map((image, index) => (
        <div className="col" key={index}>
          <div className="card shadow-sm">
            <div className="image-container">
              <img
                src={`./assets/images${image.href}`}
                alt={image.keyword}
                className="bd-placeholder-img card-img-top"
                width="auto"
                height="225px"
              />
              <div className="overlay">
                <div className="image-keywords">
                <div 
                  className="image-keywords" 
                  title={image.keyword} 
                >
                  {image.keyword}
                </div>
                </div>
                <button 
                  className="select-button" 
                  onClick={() => handleSelect(image.keyword)} 
                >
                  SELECT
                </button>
              </div> 
            </div>
            {/* <div className="card-body">
              <p className="fw-bold">{image.title}</p>
              <p className="card-text">{image.prompt}</p>
            </div> */}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ImageList;
