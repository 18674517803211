import React from "react";
import * as Select from "@radix-ui/react-select";
import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import "./CustomDropdown.css";

const CustomDropdown = ({ label, value, options, onChange, disabled }) => {
  return (
    <div className="dropdown-container">
      <label className="dropdown-label">{label}</label>
      <Select.Root value={value} onValueChange={onChange} disabled={disabled}>
        <Select.Trigger
          className="dropdown-trigger"
          aria-label={label}
          disabled={disabled}
        >
          <Select.Value />
          <Select.Icon>
            <ChevronDownIcon className="dropdown-icon" />
          </Select.Icon>
        </Select.Trigger>
        <Select.Content className="dropdown-content">
          <Select.ScrollUpButton className="dropdown-scroll">
            <ChevronUpIcon />
          </Select.ScrollUpButton>
          <Select.Viewport className="dropdown-viewport">
            {options.map((option) => (
              <Select.Item
                key={option.value}
                value={option.value}
                className="dropdown-item"
              >
                <Select.ItemText>{option.label}</Select.ItemText>
                <Select.ItemIndicator>
                  <ChevronDownIcon />
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Viewport>
          <Select.ScrollDownButton className="dropdown-scroll">
            <ChevronDownIcon />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Root>
    </div>
  );
};

export default CustomDropdown;
